@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  background-color: #181818 !important;
}

body {
  margin: 0;
  background-color: #181818 !important;
  font-family: 'PT Sans', 'Helvetica Neue', sans-serif,  'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'PT Sans', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.titan-hybrid-msg {
  font-family: 'PT Sans', 'Helvetica Neue', sans-serif,  'Roboto';
  padding: 0px;
  margin: 0px;
  margin-bottom: 0px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #252525 !important;
}

.titan-hybrid-msg > h1 {
  display: block;
  padding: 0px;
  padding-left: 1vw;
  margin: 0px;
  margin-top: -3vh;
  color: white;
  box-sizing: border-box;
  font-family: 'PT Sans', 'Helvetica Neue', sans-serif,  'Roboto';
}

.titan-hybrid-msg > h2 {
  display: block;
  padding: 0px;
  padding-left: 1vw;
  margin: 0px;
  margin-top: -2vh;
  color: white;
  box-sizing: border-box;
}


.titan-hybrid-msg > ul {
  display: block;
  padding: 0px;
  padding-left: 3vw;
  margin: 0px;
  margin-top: -3vh;
  margin-bottom: -7vh;
  color: white;
  box-sizing: border-box;
  /* border: 1px solid red; */
}
ul ul {
  margin-left: 0px;
  margin-top: -3vh;
  margin-bottom: -7vh;
}

.titan-hybrid-msg > ul > li {
  list-style-type: square;
  padding: 0px;
  margin: 0px;
  margin-top: -2vh;
  font-family: 'Helvetica Neue';

}

.titan-hybrid-msg > table {
  background-color: #333;
  color: #fff;
  width: 90%;
  margin-top: -3vh;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  table-layout: fixed;
}

.titan-hybrid-msg > table > thead {
  background-color: #181818 !important;
  color: #fff;
  border-collapse: collapse;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  height: 30px;
  font-family: 'Helvetica Neue';
  font-size: 14px;
}

.titan-hybrid-msg > table > tbody {
  color: #fff;
  width: 'auto';
  /* border: 1px solid red; */
  text-align: center;
  font-family: 'Helvetica Neue';
}

.titan-hybrid-msg > table > tbody > tr {
  height: 3vh;
  font-size: 13px; 
}

.titan-hybrid-msg > table > tbody > tr > th {
  background-color: #181818 !important;
}

@keyframes typing {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes llm-typing {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 100vh;
  }
}

.titan-markdown-container {
  font-family: 'PT Sans', 'Helvetica Neue';
  padding: 0px;
  padding-top: 1vh;
  margin: 0px;
  box-sizing: border-box;
  overflow: hidden;
  animation: llm-typing 1s steps(1, end)
  /* border: 1px solid yellow; */
}

.titan-markdown-container > p {
  font-family: 'PT Sans';
  padding: 0px;
  padding-left: 1vw;
  padding-right: 1vw;
  margin: 0px;
  margin-bottom: 0px;
  box-sizing: border-box;
  overflow: hidden;
  text-align: justify;
}

.titan-markdown-container > ul {
  display: block;
  padding: 0px;
  padding-left: 3vw;
  margin: 0px;
  margin-top: -1vh;
  margin-bottom: -1vh;
  color: white;
  box-sizing: border-box;
}

.titan-markdown-container > ul > li {
  list-style-type: square;
  padding: 0px;
  margin: 0px;
  margin-top: -1vh;
  font-family: 'PT Sans';
}